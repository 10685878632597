import { useEffect, useState } from "react";
import { Modal, Form, Input, message, Checkbox, Select, InputNumber, DatePicker, Radio } from "antd";
import { getAllInventorySupportingData, getAllOutgoingTrucks, getAllVendors, startInventoryItemsDownload, startOutgoingTruckDownload, startPalletsDownload } from "../../actions";
import dayjs from "dayjs";

import { MANIFEST_ITEM_DOWNLOAD_TYPES } from "../../constants";
import { DATETIME_BACKEND_FORMAT, DISPLAY_FULL_DATETIME_FORMAT } from "../../global_constants";
import { SelectSearchByLabel } from "../../helpers";

const { RangePicker } = DatePicker;

const StartDownloadModal = ({
  isModalOpen,
  setIsModalOpen,
  isReceivingDownload = false,
  isInventoryItemsDownload = false,
  isPalletsDownload = false,
  isOutgoingTruckDownload = false,
}) => {
  const [form] = Form.useForm();
  const [isDownloading, setIsDownloading] = useState(false);

  const [deliveryLoads, setDeliveryLoads] = useState([]);
  const [inventoryGroups, setInventoryGroups] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [outgoingTrucks, setOutgoingTrucks] = useState([]);

  const modalTitle = `Start Download - ${isReceivingDownload ? "Receiving Items" : ""} ${isInventoryItemsDownload ? "Inventory Items" : ""} ${isPalletsDownload ? "Pallets" : ""} ${isOutgoingTruckDownload ? "Outgoing Truck" : ""}`;

  const getInventorySupportingData = () => {
    getAllInventorySupportingData().then((response) => {
      const { data } = response;
      setDeliveryLoads(data.delivery_loads.map(
        (item) => {
          item.key = item.id
          return item;
        }
      ));
      setInventoryGroups(data.inventory_groups.map(
        (item) => {
          item.key = item.id
          return item;
        }
      ));
      setVendors(data.vendors.map(
        (item) => {
          item.key = item.id
          return item;
        }
      ));
    }).catch((err) => {
      message.error(err?.response?.data?.detail);
    });
  }

  const getOutgoingTrucks = () => {
    getAllOutgoingTrucks().then((response) => {
      const { data } = response;
      setOutgoingTrucks(data.map(
        (item) => {
          item.key = item.id
          return item;
        }
      ));
    }).catch((err) => {
      message.error(err?.response?.data?.detail);
    });
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const startDownload = (values) => {
    let selectedMode = values.item_download_type;
    if (!selectedMode) {
      // default: ManifestOnly - outgoing truck will use this, maybe some others will too
      selectedMode = "ManifestOnly";
    }
    /*
      Specified by Evan:
      Manifest With Photos
      - all manifest items with photos
      - featured only items with photos

      Manifest only
      - all manifest items without photos
      - featured items without photos

      Featured items:
      - featured items with photos
    */
    if (selectedMode === "ManifestWithPhotos") {
      values.include_photos = true;
      values.item_download_type = "All";
    } else if (selectedMode === "ManifestOnly") {
      values.include_photos = false;
      values.item_download_type = "All";
    } else if (selectedMode === "FeaturedOnly") {
      values.include_photos = true;
      values.item_download_type = "OnlyFeatured";
    } else {
      message.error("Invalid download type - please contact support");
      return;
    }

    const combineIdsAsString = ["delivery_load_ids", "inventory_group_ids", "product_vendor_ids"];
    combineIdsAsString.forEach((key) => {
      if (values[key]) {
        values[key] = values[key].join(",");
      }
    });

    let functionToCall = null;

    if (isReceivingDownload) {
      values.is_receiving = true;
      values.is_inventory_items = false;
      functionToCall = startInventoryItemsDownload;
    } else if (isInventoryItemsDownload) {
      values.is_receiving = false;
      values.is_inventory_items = true;
      functionToCall = startInventoryItemsDownload
    } else if (isPalletsDownload) {
      values.is_receiving = false;
      values.is_inventory_items = false;
      functionToCall = startPalletsDownload
    } else if (isOutgoingTruckDownload) {
      values.is_receiving = false;
      values.is_inventory_items = false;
      functionToCall = startOutgoingTruckDownload
    }
    else {
      message.error("Invalid download type - please contact support");
      return;
    }
    let startDatetime = values?.start_end_datetime?.[0];
    let endDatetime = values?.start_end_datetime?.[1];
    if (startDatetime) {
      values.start_datetime = startDatetime.format(DATETIME_BACKEND_FORMAT);
    }
    if (endDatetime) {
      values.end_datetime = endDatetime.format(DATETIME_BACKEND_FORMAT);
    }

    setIsDownloading(true);
    functionToCall(values).then((response) => {
      setIsDownloading(false);
      const { data } = response;
      const { id } = data;
      setIsModalOpen(false);
      message.success("Download started successfully. You can use the manyfastScan portal to monitor the download.", 10);
    }).catch((err) => {
      setIsDownloading(false);
      message.error(err?.response?.data?.detail);
    })
  };

  useEffect(() => {
    if (isModalOpen) {
      getInventorySupportingData();

      if (isOutgoingTruckDownload) {
        getOutgoingTrucks();  
      }

      setTimeout(() => {
        form.resetFields();
      }, 50);
    }
  }, [isModalOpen]);

  return (
    <Modal
      title={modalTitle}
      okText={"Download"}
      cancelText="Close"
      open={isModalOpen}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      confirmLoading={isDownloading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => startDownload(values)}
        initialValues={{
          item_download_type: MANIFEST_ITEM_DOWNLOAD_TYPES[0].value, // ManifestWithPhotos
          start_end_datetime: [dayjs().add(-14, 'd'), dayjs()]
        }}
      >
        <If condition={isOutgoingTruckDownload}>
          <Form.Item
            label="Outgoing Truck"
            name="outgoing_truck_id"
            rules={[{ required: true, message: "Please select outgoing truck" }]}
          >
            <Select
              placeholder="Select Outgoing Truck"
              allowClear
              options={outgoingTrucks.map(x => ({ label: x.name, value: x.id }))}
              filterOption={SelectSearchByLabel}
            />
          </Form.Item>
        </If>

        <If condition={isReceivingDownload || isInventoryItemsDownload || isPalletsDownload}>
          <Form.Item
            label="Download Type"
            name="item_download_type"
            rules={[{ required: true, message: "Please select download type" }]}
          >
            <Radio.Group>
              {MANIFEST_ITEM_DOWNLOAD_TYPES.map((item) => {
                return (
                  <Radio value={item.value}>{item.label}</Radio>
                )
              })}
            </Radio.Group>
          </Form.Item>
          
          <Form.Item
            label="Created/Updated Start - End"
            name="start_end_datetime"
          >
            <RangePicker
              allowClear
              format={DISPLAY_FULL_DATETIME_FORMAT}
              showTime
              onChange={(dates) => {
                console.log(dates);
              }}
              presets={[
                {
                  label: 'Yesterday',
                  value: [dayjs().add(-1, 'd'), dayjs()],
                },
                {
                  label: 'Last 7 Days',
                  value: [dayjs().add(-7, 'd'), dayjs()],
                },
                {
                  label: 'Last 14 Days',
                  value: [dayjs().add(-14, 'd'), dayjs()],
                },
                {
                  label: 'Last 30 Days',
                  value: [dayjs().add(-30, 'd'), dayjs()],
                },
                {
                  label: 'Last 90 Days',
                  value: [dayjs().add(-90, 'd'), dayjs()],
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Delivery Load"
            name="delivery_load_ids"
          >
            <Select
              mode="multiple"
              placeholder="Select delivery load(s)"
              allowClear
              options={deliveryLoads.map(x => ({ label: x.unique_identifier, value: x.id }))}
              filterOption={SelectSearchByLabel}
            />
          </Form.Item>

          <Form.Item
            label="Inventory Group"
            name="inventory_group_ids"
          >
            <Select
              mode="multiple"
              placeholder="Select inventory group(s)"
              allowClear
              options={inventoryGroups.map(x => ({ label: x.name, value: x.id }))}
              filterOption={SelectSearchByLabel}
            />
          </Form.Item>

          <Form.Item
            label="Vendor"
            name="product_vendor_ids"
          >
            <Select
              mode="multiple"
              placeholder="Select vendor(s)"
              allowClear
              options={vendors.map(x => ({ label: x.vendor_name, value: x.id }))}
              filterOption={SelectSearchByLabel}
            />
          </Form.Item>
        </If>
      </Form>
    </Modal>
  );
};

export default StartDownloadModal;
