import { useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import { version } from '../package.json';

import { Spin } from "antd";
import { isEmpty } from "./_dash";
import { getAuthUserDetail } from "./actions";

import { NON_LOGIN_ROUTES, LOGGED_IN_ROUTES } from "./routes";

import AccountHook from "./hooks/account";
import RouteWithSubRoutes from "./components/routeWithSubRoutes";
import CacheBuster from "react-cache-buster";

const App = ({ accountData, setAccountData }) => {
  const isLoggedIn = !isEmpty(accountData);

  const [loading, setLoading] = useState(true);
  const [appRoutes, setAppRoutes] = useState(NON_LOGIN_ROUTES);

  useEffect(() => {
    getAuthUserDetail()
      .then((response) => {
        setAccountData(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!loading) {
      if (isLoggedIn) {
        setAppRoutes(LOGGED_IN_ROUTES);
      } else {
        setAppRoutes(NON_LOGIN_ROUTES);
      }
    }
  }, [isLoggedIn, loading]);

  if (loading) {
    return <Spin />;
  }

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<Spin />} //If not pass, nothing appears at the time of new version check.
    >
      <BrowserRouter>
        <Switch>
          {appRoutes.map((route, i) => {
            return <RouteWithSubRoutes key={i} {...route} />;
          })}
        </Switch>
      </BrowserRouter>
    </CacheBuster>
  );
};

export default AccountHook(App);
