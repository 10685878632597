import axios from "axios";
import responseErrorService from "./utilities/responseErrorHandling";
import { DEVICE_ID } from "./global_constants";
import { v4 as uuid } from "uuid";

const deviceId = localStorage.getItem(DEVICE_ID);
if (!deviceId) {
  const randomUuid = uuid();
  localStorage.setItem(DEVICE_ID, randomUuid);
}

let headers = {
  "Content-Type": "application/json",
  "Client-Type": "web-inventory",
  "X-Device-Id": localStorage.getItem(DEVICE_ID),
};

const axiosInstance = axios.create({
  headers,
  timeout: 16000000,
  baseURL: `${process.env.REACT_APP_MFS_API_HOST}/api/v1/`,
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // TODO: check if try-catch is necessary
    try {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error?.message);
        return Promise.reject(error);
      }
    } catch (error) { }

    responseErrorService.handle(error);

    return Promise.reject(error);
  }
)

export default axiosInstance;
